import React, {useEffect, useState} from 'react';
import {DataGrid, GridColDef, GridRenderCellParams, GridRowModel} from "@mui/x-data-grid";
import {Avatar, Box, Button, CircularProgress, Stack, Typography} from "@mui/material";
import {formatDateTime, getName} from "../../../global/helpers/text";
import {deleteStudentFromGroup, getAllCorrectionsByGroupId} from "../../actions/api";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import { useUserStore } from '../../../global/store/UserStore';
import CorrectionDialog from './CorrectionDialog';
import useLoadingHook from '../../../global/hooks/UseLoadingHook';

interface StudentsGridProps {
    rows: any[];
    fetchGroupInfo: () => void;
    // fetchCorrections: (id: any) => void;
    groupInfo: any;
}

const NoRows = () => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', p: 5}}>
            <Typography>
                Нет данных
            </Typography>
        </Box>
    )
}

const StudentsGrid = ({rows, fetchGroupInfo, groupInfo} : StudentsGridProps) => {

    // TODO: добавить обновление corrections после подачи заявки

    const snackbar = useSnackbarStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const currentUser = useUserStore((state) => state);
    const [loadingIds, setLoadingIds] = useState<number[]>([]);
    const [correctionDialogOpen, setCorrectionDialogOpen] = useState<boolean>(false);
    const [studentId, setStudentId] = useState<number | null>(null);
    const [correctionsList, correctionsLoading, , fetchCorrections] = useLoadingHook(getAllCorrectionsByGroupId);
    const [correctionStudentList, setCorrectionStudentList] = useState<number[]>([]);
    
    const handleCorrectionOpen = (studentId: number) => {
        setStudentId(studentId);
        setCorrectionDialogOpen(true);
    };

    useEffect(() => {
        fetchCorrections(groupInfo.id);
    }, []);

    useEffect(() => {
        if (correctionsList) {
            const studentsIds = correctionsList.map((correction: any) => correction.student_id);
            setCorrectionStudentList(studentsIds);
        }
    }, [correctionsList]);
    

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 90,
            sortable: true,
            renderCell: (params: GridRenderCellParams<any>) =>
                params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
        },
        {
            field: 'name', headerName: 'ФИО', minWidth: 350, sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {
            field: 'iin',
            headerName: 'ИИН',
            width: 150,
            sortable: false,
        },
        {
            field: 'medical_certificate_number',
            headerName: 'Номер мед. справки',
            width: 200,
            sortable: false,
        },
        {
            field: 'medical_center_title',
            headerName: 'Название мед. центра',
            width: 200,
            sortable: false,
        },
        {
            field: 'medical_center_bin',
            headerName: 'БИН мед. центра',
            width: 200,
            sortable: false,
        },
        {
            field: 'medical_examination_date',
            headerName: 'Дата прохождения мед. осмотра',
            width: 300,
            sortable: false,
        },
        {
            field: 'created_at',
            headerName: 'Дата прикрепления',
            width: 180,
            sortable: true,
            valueGetter: ({row}) => formatDateTime(row.created_at)
        },
        {
            field: 'actions',
            headerName: 'Действия',
            width: 500,
            sortable: false,
            renderCell:({row}: GridRowModel<any>) => GridButtons(row)
        }
    ];

    const GridButtons = (row: GridRowModel<any>) => {

        if (!row.medical_certificate_number || !row.medical_center_title || !row.medical_center_bin || !row.medical_examination_date) {
            return (
                <Stack>
                    <Button
                        variant={'contained'}
                        onClick={() => {handleCorrectionOpen(row.id)}}
                        sx={{height: 'auto'}}
                        disabled={correctionStudentList.includes(row.id)}
                    >
                        Добавить данные мед. осмотра
                    </Button>
                </Stack>
            )
        } else {
            return (
              <Stack>
                  <Button
                    disabled={true}
                    variant={'contained'}
                    onClick={() => {handleCorrectionOpen(row.id)}}
                    sx={{height: 'auto'}}
                  >
                      Добавить данные мед. осмотра
                  </Button>
              </Stack>
            )
        }
    }

    const handleDelete = (row: GridRowModel<any>) => {
        setLoadingIds((prev) => {
            return [...prev, row.id];
        })

        deleteStudentFromGroup(row.id)
            .then((res) => {
                console.log(res);
                snackbar.successMessage('Курсант успешно удален из группы')
            })
            .catch((err) => {
                console.log(err);
                snackbar.errorMessage(err.response.data.message);
            })
            .finally(() => {
                setLoadingIds((prev) => {
                    return prev.filter((id) => {
                        if (id === row.id) {
                            return false;
                        } else {
                            return true;
                        }
                    })
                })
                fetchGroupInfo();
            })
    }

    return (
        <Box>
            <DataGrid
                columns={columns}
                rows={rows}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 30,
                        },
                    },
                }}
                pageSizeOptions={[30]}
                disableRowSelectionOnClick={true}
                autoHeight={true}
                slots={{
                    noRowsOverlay: NoRows
                }}
                loading={correctionsLoading}
            />
            {studentId && (
                <CorrectionDialog 
                    fetchGroupInfo={fetchGroupInfo}
                    fetchCorrections={fetchCorrections}
                    groupInfo={groupInfo}
                    open={correctionDialogOpen}
                    onClose={() => {setCorrectionDialogOpen(false)}}
                    studentId={studentId}
                />
            )}
        </Box>
    );
};

export default StudentsGrid;