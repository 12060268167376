import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import { useUserStore } from '../../global/store/UserStore';
import PageHeader from '../../global/components/PageHeader';
import { wrapperSx } from '../../global/helpers/globalstyles';
import KursantAuthDialog from '../components/KursantAuthDialog';
import ActionButton from '../../Landing/components/ActionButton';
import PaymentIcon from '@mui/icons-material/Payment';
import KursantIcon from '../../global/assets/images/KursantIcon';
import { AccessTime, Copyright, House, LocalPhone } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';

const BuyingPage = () => {

  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const snackbar = useSnackbarStore((state) => state);
  const userStore = useUserStore((state) => state);

  const [authDialog, setAuthDialog] = useState<boolean>(false);

  useEffect(() => {
    if (!userStore.iin || !userStore.last_name) {
      snackbar.infoMessage('Необходимо ввести свой ИИН и фамилию для продолжения покупки');
      setAuthDialog(true);
    }
  }, [])

  return (
    <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <PageHeader/>
      <Box sx={{...wrapperSx, py: 3, background: '#F8F9FB'}} display={'flex'} flexDirection={'column'} gap={2}>
        <Stack flexDirection={isMobile ? 'column' : 'row'} justifyContent={'space-between'}>
          <Typography
            variant={'h5'}
          >
            Покупка активационных для пробного тестирования.
          </Typography>

          <Button variant="contained" startIcon={<PaymentIcon/>} onClick={() => navigate(`/tests`)}
                  sx={{mt: 2}}>
            Применить активационный код
          </Button>
        </Stack>
        <Stack gap={1}>

          <Stack flexDirection={'row'} gap={2}>
            <ActionButton
              title={"1 активационный код"}
              subtitle={"Проверьте свои знания перед реальной сдачей экзамена в СпецЦОНе"}
              text={"3450 тг."}
              onClick={() => {snackbar.infoMessage('Ожидается подключение платежной системы')}}
              backgroundImage={"url(/images/education.png)"}
            />

            <ActionButton
              title={"Пакет (3) активационных кодов"}
              subtitle={"Пакет из 3 прохождений со скидкой -20%"}
              text={"8000 тг."}
              onClick={() => {snackbar.infoMessage('Ожидается подключение платежной системы')}}
              backgroundImage={"url(/images/akcia.png)"}
            />
          </Stack>

          <Stack flexDirection={'row'} alignItems={'center'} gap={2}>
            <Typography>
              На данный момент функционал находится в разработке. Ожидается подключение платежной системы.
            </Typography>
          </Stack>

          <Stack flexDirection={'row'} alignItems={'center'} gap={2}>
            <Typography>
              Благодарим за терпение и за то, что остаетесь с нами.
            </Typography>
          </Stack>

          <Stack flexDirection={'row'} alignItems={'center'} gap={2}>
            <Typography>
              С уважением, команда ИС "Курсант"
            </Typography>
          </Stack>

        </Stack>

        <KursantAuthDialog
          open={authDialog}
          onClose={() => {setAuthDialog(false)}}
        />
      </Box>

      <Divider />

      <Box component="footer" sx={{ bgcolor: 'background.paper', py: 2, marginTop: 'auto'}}>
        <Container maxWidth="xl">
          <Stack flexDirection={'row'} justifyContent={'space-between'}>
            <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
              <KursantIcon width={'125px'} height={'100px'} />
              <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                <Typography variant={'caption'}>
                  <a href="/UserOffer.docx" download="Пользовательское соглашение Kursant KZ"
                     target="_blank"
                     rel="noreferrer"
                     style={{
                       color: '#ce8838',
                       textDecoration: 'none'
                     }}
                  >
                    Пользовательское соглашение
                  </a>
                </Typography>
              </Stack>
              <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                <Typography variant={'caption'}>
                  <a href="/privacy.docx" download="Политика конфиденциальности Kursant KZ"
                     target="_blank"
                     rel="noreferrer"
                     style={{
                       color: '#ce8838',
                       textDecoration: 'none'
                     }}
                  >
                    Политика конфиденциальности
                  </a>
                </Typography>
              </Stack>
            </Stack>
            <Stack>
              <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                <Avatar
                  sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                >
                  <LocalPhone fontSize={'small'}/>
                </Avatar>
                <Typography variant={'caption'}>
                  <b>Телефон - 8(776)-168-44-11</b>
                </Typography>
              </Stack>
              <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                <Avatar
                  sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                >
                  <AccessTime fontSize={'small'}/>
                </Avatar>
                <Typography variant={'caption'}>
                  10:00 - 18:00 (GMT +6)
                </Typography>
              </Stack>
              <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                <Avatar
                  sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                >
                  <Copyright fontSize={'small'}/>
                </Avatar>
                <Typography variant={'caption'} textAlign={'right'}>
                  Kursant KZ, 2024 г.
                </Typography>
              </Stack>
              <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                <Avatar
                  sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                >
                  <InfoIcon fontSize={'small'}/>
                </Avatar>
                <Typography variant={'caption'} textAlign={'right'}>
                  БИН: 230840010667
                </Typography>
              </Stack>
              <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                <Avatar
                  sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                >
                  <House fontSize={'small'}/>
                </Avatar>
                <Typography variant={'caption'} textAlign={'right'}>
                  Астана, р. Алматы, ул. Кайрата Рыскулбекова, Дом 27/1
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

export default BuyingPage;
